import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './Nav.module.scss';

import Banner from '@/components/Banner/Banner';

import ScrollService from '@/services/scroll';
import { isLinkActive } from '@/utils/basic-functions';
import useLayout from '@/hooks/use-layout';
import { NavbarProps } from '@/types';

import routes from '@/data/routes';

import { BrandLogoSVG, DropdownSVG } from '../../assets/svgs';
import MobileNav from '../MobileNav/MobileNav';

function Nav({ className, isTransparent, links, externalLinks }: NavbarProps) {
  const { mobile } = useLayout();
  const [scrollDir, setScrollDir] = useState(Number);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scrollOffsetTransparent, setScrollOffsetTransparent] = useState(0);
  const [activeDropdownItem, setActiveDropdownItem] = useState<null | number>(null);
  const [navState, setNavState] = useState(String);
  const refNav = useRef() as MutableRefObject<HTMLDivElement>;
  const router = useRouter() ?? {};

  function animatePageIn() {
    // TODO add banner here or in its own component
    gsap.from(refNav.current, {
      autoAlpha: 0,
      duration: 0.667,
      ease: 'linear',
      delay: 0.397
    });
  }

  useEffect(() => {
    !mobile && animatePageIn();
    if (refNav.current) {
      const threshold = 0;
      let lastScrollY = window.pageYOffset;
      ScrollService.listen((event) => {
        if (event !== null) {
          let scrollY = window.pageYOffset;
          if (Math.abs(scrollY - lastScrollY) < threshold) {
            return;
          }
          setScrollDir(scrollY > lastScrollY ? 1 : -1);
          lastScrollY = scrollY > 0 ? scrollY : 0;
          // hide nav once scroll past nav height offset
          refNav !== null && scrollY > refNav.current?.clientHeight && !mobile
            ? setScrollOffset(1)
            : setScrollOffset(0);
          // same but when the nav is transparent, so when nav hides for the first time, it does not change to white before hiding.
          refNav !== null && scrollY > refNav.current?.clientHeight * 3
            ? setScrollOffsetTransparent(1)
            : setScrollOffsetTransparent(0);
        }
      });
    }
  }, [mobile]);

  useEffect(() => {
    const scrollState = scrollOffset === 1 && scrollDir === 1;
    scrollState ? setNavState(styles.hidden) : setNavState('');
  }, [scrollDir, scrollOffset, navState]);

  return (
    <>
      {router.asPath === routes.Home.path ? <Banner title="Latest announcement" /> : null}
      <nav
        className={classnames(styles.Nav, navState, className, {
          [styles.transparent]: !scrollOffsetTransparent && isTransparent
        })}
        ref={refNav}
      >
        <div className={styles.overlay} />
        {mobile ? (
          <MobileNav
            isTransparent={!scrollOffsetTransparent && isTransparent}
            links={links}
            externalLinks={externalLinks}
          />
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.logoCon}>
              <Link href={routes.Home.path}>
                <a aria-label="logo-link">
                  <BrandLogoSVG className={styles.logo} />
                </a>
              </Link>
            </div>
            <ul className={styles.routes}>
              {links?.map((link, index: number) =>
                !link?.parentId ? (
                  <li
                    className={classnames({
                      [styles.isDropdown]: link?.childItems?.nodes?.length > 0,
                      [styles.activeRoute]: isLinkActive(router.asPath, link?.path ?? ''),
                      [styles.active]: activeDropdownItem === index
                    })}
                    key={index}
                  >
                    {!link?.parentId && link?.childItems?.nodes?.length ? (
                      <div
                        className={classnames([
                          styles.hasChildren,
                          styles[`children-${link?.childItems?.nodes?.length}`]
                        ])}
                        onMouseEnter={() => setActiveDropdownItem(index)}
                        onClick={() => setActiveDropdownItem(!activeDropdownItem ? index : null)}
                        onMouseLeave={() => setActiveDropdownItem(null)}
                      >
                        <div className={styles.overlay} />
                        <button className={styles.link}>
                          {link.label}
                          <span className={styles.circle}>
                            <DropdownSVG className={styles.svg} />
                          </span>
                        </button>
                        <div className={styles.childrenItems}>
                          {link?.childItems?.nodes?.map((link, nestedIndex: number) => (
                            <Link href={link?.path ?? ''} key={`${index}-${nestedIndex}`}>
                              <a
                                aria-label={`${link?.label}-menu`}
                                className={classnames({
                                  [styles.activeRoute]: isLinkActive(
                                    router.asPath,
                                    link?.path ?? ''
                                  )
                                })}
                              >
                                {link?.label}
                                <DropdownSVG className={styles.svg} />
                              </a>
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link href={link?.path ?? ''}>
                        <a aria-label={`${link?.label}-menu`}>
                          {link?.label}
                          <span className={styles.bottomLine} />
                        </a>
                      </Link>
                    )}
                  </li>
                ) : null
              )}
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}

export default Nav;
